const globalState = (state = { backgroundColor: "#FF6633"}, action) => {
  switch (action.type) {
    case 'CHANGE_BACKGROUND':
      return [
        ...state,
        {
          backgroundColor: action
        }
      ]
    default:
      return state
  }
}

export default globalState
