// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-book-a-table-js": () => import("./../../src/templates/book-a-table.js" /* webpackChunkName: "component---src-templates-book-a-table-js" */),
  "component---src-templates-find-us-js": () => import("./../../src/templates/find-us.js" /* webpackChunkName: "component---src-templates-find-us-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-menu-downloads-js": () => import("./../../src/templates/menu-downloads.js" /* webpackChunkName: "component---src-templates-menu-downloads-js" */),
  "component---src-templates-menus-js": () => import("./../../src/templates/menus.js" /* webpackChunkName: "component---src-templates-menus-js" */),
  "component---src-templates-prices-js": () => import("./../../src/templates/prices.js" /* webpackChunkName: "component---src-templates-prices-js" */),
  "component---src-templates-restaurant-hours-js": () => import("./../../src/templates/restaurant-hours.js" /* webpackChunkName: "component---src-templates-restaurant-hours-js" */)
}

